<template>
  <v-form ref="form">
    <p>Complétez les informations suivantes en précisant les mois d'activités.</p>

    <p v-if="!student.hasStudiedInBelgium">
      Pour les spécialisations (SIAMU – PEDIATRIE),
      précisez les activités post-bachelier en Soins Infirmiers.
    </p>

    <p>
      <ul>
        <li>
          <span class="text-decoration-underline">Si vous suiviez des études :</span>
          renseignez le nom de l' <span class="font-weight-bold">établissement</span>
          , la <span class="font-weight-bold">section - option</span>
          , le <span class="font-weight-bold">nombre de crédits à l'inscription</span>
          et le <span class="font-weight-bold">nombre de crédits valorisés</span>.
        </li>

        <li>
          <span class="text-decoration-underline">Si vous travailliez :</span>
          renseignez le nom de votre <span class="font-weight-bold">employeur</span>
          ainsi que le <span class="font-weight-bold">domaine de l'emploi</span>.
        </li>

        <li>
          <span class="text-decoration-underline">Si vous êtiez au chômage :</span>
          indiquez-le.
        </li>
      </ul>
    </p>

    <v-textarea
      v-for="(year, index) in academicYears"
      :key="index"
      :label="`En ${year}`"
      :value="student.activities[index].text"
      @input="addActivities(year, index, $event)"
      required
      :rules="commentsRules"
      filled
      no-resize
      rows="3"
    ></v-textarea>
  </v-form>
</template>

<script>
import { student } from '@/mixins/dataMixin';
import { validate, updateStudent, getAcademicYear } from '@/mixins/methodsMixin';

export default {
  name: 'FormStepHistory',

  mixins: [
    validate,
    updateStudent,
    student,
    getAcademicYear,
  ],

  data: () => ({
    commentsRules: [
      (v) => !!v || 'Ce champ est requis',
      (v) => (v && v.length <= 512) || 'Taille maximale de 512 caractères',
    ],
  }),

  computed: {
    academicYears() {
      const current = this.getAcademicYear(new Date());
      const { start: graduation } = this.getAcademicYear(new Date(this.student.graduationDate));
      const res = [];
      for (let i = 0; i < 5; i += 1) {
        if (current.start - i === graduation) break;
        res.push(`${current.start - i} - ${current.end - i}`);
      }
      return res;
    },
  },

  methods: {
    addActivities(year, index, text) {
      this.$store.commit('student/UPDATE_ACTIVITIES', {
        index,
        value: { year, text },
      });
    },
  },
};
</script>

<template>
  <v-form ref="form">
    <v-radio-group
      label="Avez-vous effectué vos études secondaires supérieures ou équivalentes en Belgique ?"
      :value="student.hasStudiedInBelgium"
      @change="handleStudyLocationUpdate($event)"
      mandatory
    >
      <v-radio
        label="Oui"
        :value="true"
      ></v-radio>

      <v-radio
        label="Non"
        :value="false"
      ></v-radio>
    </v-radio-group>

    <v-radio-group
      :label="`Etes-vous dans l’année d’obtention du ${graduationTitle}?`"
      :value="student.willGraduateThisYear"
      @change="updateStudent('willGraduateThisYear', $event)"
      mandatory
    >
      <v-radio
        label="Oui"
        :value="true"
      ></v-radio>

      <v-radio
        label="Non"
        :value="false"
      ></v-radio>
    </v-radio-group>

    <template v-if="!student.willGraduateThisYear">
      <v-menu
        ref="graduationDateMenu"
        v-model="graduationDateMenuIsActive"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="468"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Date d'obtention"
            :value="student.graduationDate | formatDate"
            required
            :rules="rules.graduationDate"
            v-bind="attrs"
            v-on="on"
            readonly
            filled
          ></v-text-field>
        </template>

        <v-date-picker
          ref="graduationDatePicker"
          :value="student.graduationDate"
          @input="updateStudent('graduationDate', $event)"
          :max="maxDateDegree"
          :min="minDateDegree"
          @change="saveDate('graduationDateMenu', $event)"
          no-title
          first-day-of-week="1"
          full-width
        ></v-date-picker>
      </v-menu>

      <v-text-field
        label="Nom de l'établissement"
        :value="student.establishmentName"
        @input="updateStudent('establishmentName', $event)"
        required
        :rules="rules.establishmentName"
        validate-on-blur
        filled
      ></v-text-field>

      <v-autocomplete
        label="Pays de l'établissement"
        :value="student.establishmentCountry"
        @input="updateStudent('establishmentCountry', $event)"
        :items="availableCountries"
        item-text="country_name"
        item-value="code"
        required
        :rules="rules.country"
        :loading="countriesAreLoading"
        validate-on-blur
        auto-select-first
        hide-no-data
        filled
        :disabled="student.hasStudiedInBelgium"
      ></v-autocomplete>

      <v-radio-group
        v-if="!student.hasStudiedInBelgium"
        label="Avez-vous introduit une demande
        d'équivalence de votre Baccalauréat au Ministère de la F.W.B. ?"
        :value="student.hasEquivalence"
        @change="updateStudent('hasEquivalence', $event)"
        mandatory
      >
        <v-radio
          label="Oui, et je l'ai déjà obtenue"
          value="yes"
        ></v-radio>

        <v-radio
          label="Oui, mais je ne l'ai pas encore obtenue"
          value="pending"
        ></v-radio>

        <v-radio
          label="Non"
          value="no"
        ></v-radio>
      </v-radio-group>
    </template>

    <v-alert
      v-if="!student.hasStudiedInBelgium"
      color="info"
      text
      class="text-justify"
      dense
    >
      <div class="text-decoration-underline font-weight-bold">Pour rappel :</div>

      Si vous avez accompli vos études secondaires à l'étranger et que vous souhaitez vous
      inscrire à la HEPH-Condorcet, vous devez introduire une demande d'équivalence de votre
      diplôme auprès de la Fédération Wallonie-Bruxelles avant le 15 juillet précédant la
      rentrée académique. Plus d’informations sur ce

      <a href="http://www.equivalences.cfwb.be" target="_blank">lien</a>
    </v-alert>
  </v-form>
</template>

<script>
import { enfStudentFormRules, student } from '@/mixins/dataMixin';
import {
  validate,
  updateStudent,
  saveDate,
  getAcademicYear,
} from '@/mixins/methodsMixin';

export default {
  name: 'FormStepPreviousStudies',

  mixins: [
    enfStudentFormRules,
    validate,
    updateStudent,
    student,
    saveDate,
    getAcademicYear,
  ],

  props: {
    countries: Array,
    countriesAreLoading: Boolean,
  },

  data: () => ({
    graduationDateMenuIsActive: false,
  }),

  computed: {
    graduationTitle() {
      return this.student.hasStudiedInBelgium ? 'cess' : 'baccalauréat';
    },

    minDateDegree() {
      if (!this.student.birthDate) return '1950-01-01';

      const date = new Date(this.student.birthDate);
      date.setFullYear(date.getFullYear() + 15);
      return date.toISOString().substr(0, 10);
    },

    maxDateDegree() {
      const { start } = this.getAcademicYear(new Date());
      // max date should be last day of previous academic year (13 september)
      const date = new Date(start, 8, 14);
      return date.toISOString().substr(0, 10);
    },

    availableCountries() {
      if (this.student.hasStudiedInBelgium) {
        return this.countries.filter((country) => (country.country_name === 'Belgique'));
      }
      return this.countries.filter((country) => (country.country_name !== 'Belgique'));
    },
  },

  watch: {
    graduationDateMenuIsActive(val) {
      if (val) setTimeout(() => { this.$refs.graduationDatePicker.activePicker = 'YEAR'; });
    },
  },

  methods: {
    handleStudyLocationUpdate(hasStudiedInBelgium) {
      if (hasStudiedInBelgium) {
        this.updateStudent('establishmentCountry', 'BE');
        this.updateStudent('hasEquivalence', null);
      } else this.updateStudent('establishmentCountry', null);
      this.updateStudent('hasStudiedInBelgium', hasStudiedInBelgium);
    },
  },
};
</script>

<template>
  <v-card
    max-width="500"
    class="mx-auto"
    outlined
  >
    <v-card-title class="justify-center">
      <div class="mb-2">{{ currentTitle.text }}</div>

      <v-img
        :src="require(`@/assets/${currentTitle.picture}.svg`)"
        height="100"
        contain
      ></v-img>
    </v-card-title>

    <v-window v-model="step" touchless>
      <v-window-item :value="1" eager>
        <v-card-text>
          Ce formulaire vous permet d’introduire une demande de préinscription dans les sections
          de la HEPH – Condorcet suivantes :

          <ul>
            <li
              v-for="cursus in cursusList"
              :key="cursus"
            >
              {{ cursus }}
            </li>
          </ul>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="2" eager>
        <v-card-text>
          <form-step-cursus
            :cursus="cursus"
            ref="step2Form"
          ></form-step-cursus>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="3" eager>
        <v-card-text>
          <registration-form-1
            ref="step3Form"
            :countries="countries"
            :countriesAreLoading="countriesAreLoading"
            :instanceType="instanceType"
          ></registration-form-1>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="4" eager>
        <v-card-text>
          <registration-form-2
            ref="step4Form"
            :instanceType="instanceType"
          ></registration-form-2>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="5" eager>
        <v-card-text>
          <registration-form-3
            ref="step5Form"
            :countries="countriesInEurope"
            :countriesAreLoading="countriesAreLoading"
            :instanceType="instanceType"
          ></registration-form-3>

          <v-alert
            color="info"
            text
            class="text-justify mt-4"
            dense
          >
            <div class="text-decoration-underline font-weight-bold">Remarque :</div>

            Les étudiants hors CEE ne peuvent pas s’inscrire via ce formulaire et doivent suivre la
            procédure d’inscription spécifique aux étudiants non finançables.

            <a
              href="https://www.condorcet.be/s-inscrire/etudiants-non-financables.html"
              target="_blank"
            >
              Plus d'infos
            </a>
          </v-alert>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="6" eager>
        <v-card-text>
          <form-step-previous-studies
            ref="step6Form"
            :countries="countries"
            :countriesAreLoading="countriesAreLoading"
          ></form-step-previous-studies>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="7" eager>
        <v-card-text>
          <form-step-history ref="step7Form"></form-step-history>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="8" eager>
        <v-card-text>
          <form-step-other-reg ref="step8Form"></form-step-other-reg>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="9" eager>
        <v-card-text>
          <v-alert
            color="info"
            text
            class="text-justify"
            dense
          >
            <div class="text-decoration-underline font-weight-bold">Attention :</div>

            Vous êtes sur le point d'envoyer votre demande de préinscription à la HEPH - Condorcet.
            En cliquant sur "envoyer", il ne vous sera plus possible de modifier votre demande.
            Assurez-vous donc que les informations reprises dans le formulaire soient exactes.
          </v-alert>
        </v-card-text>
      </v-window-item>

      <v-window-item :value="10" eager>
        <v-card-text>
          <v-alert
            color="info"
            text
            class="text-justify"
            dense
          >
            <p class="text-h6 text-center">Demande envoyée !</p>

            <div class="text-decoration-underline font-weight-bold">Remarque :</div>

            Un accusé de réception vous a été envoyé. Pensez à vérifier vos spams / mails
            indésirables. Si vous n’obtenez pas cet accusé de réception,
            vous pouvez nous contacter à l’adresse suivante :

            <a href="mailto: preinscription@condorcet.be">
              preinscription@condorcet.be
            </a>
          </v-alert>
        </v-card-text>
      </v-window-item>
    </v-window>

    <template v-if="step !== 10">
      <v-divider></v-divider>

      <v-card-actions class="justify-space-between">
        <v-btn
          color="secondary"
          :disabled="step === 1 || isSendingForm || submitIsForbidden"
          text
          @click="goBack"
        >
          Retour
        </v-btn>

        <v-item-group
          v-model="step"
          mandatory
        >
          <v-item
            v-for="n in 9"
            :key="`btn-${n}`"
            v-slot="{ active, toggle }"
            disabled
            :value="n"
          >
            <v-btn
              x-small
              :input-value="active"
              icon
              :color="n <= step ? 'primary' : ''"
              @click="jumpToStep(n, toggle)"
              v-show="n !== 7 || !willGraduateThisYear"
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>

        <v-btn
          color="primary"
          depressed
          @click="goNext"
          :loading="isSendingForm"
          :disabled="(step === 9 && isDemo) || submitIsForbidden"
        >
          {{ step === 9 ? 'Envoyer' : 'Suivant' }}
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import RegistrationForm1 from './RegistrationForm1.vue';
import RegistrationForm2 from './RegistrationForm2.vue';
import RegistrationForm3 from './RegistrationForm3.vue';
import FormStepOtherReg from './FormStepOtherReg.vue';
import FormStepPreviousStudies from './FormStepPreviousStudies.vue';
import FormStepCursus from './FormStepCursus.vue';
import FormStepHistory from './FormStepHistory.vue';

export default {
  name: 'ParaForm',

  components: {
    RegistrationForm1,
    RegistrationForm2,
    RegistrationForm3,
    FormStepOtherReg,
    FormStepPreviousStudies,
    FormStepCursus,
    FormStepHistory,
  },

  props: {
    cursus: Array,
    isDemo: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    step: 1,
    countries: [],
    countriesAreLoading: false,
    isSendingForm: false,
    instanceType: 'para',
    submitIsForbidden: false,
  }),

  created() {
    this.getCountries();
  },

  computed: {
    currentTitle() {
      switch (this.step) {
        case 1: return { text: 'Introduction', picture: 'undraw_conference_re_2yld' };
        case 2: return { text: 'Choix du cursus', picture: 'undraw_select_option_re_u4qn' };
        case 3: return { text: 'Identité', picture: 'undraw_personal_information_re_vw8a' };
        case 4: return { text: 'Contact', picture: 'undraw_contact_us_15o2' };
        case 5: return { text: 'Adresse', picture: 'undraw_My_current_location_re_whmt' };
        case 6: return { text: 'Etudes antérieures', picture: 'undraw_education_f8ru' };
        case 7: return { text: 'Activités post-secondaires / bac', picture: 'undraw_online_calendar_re_wk3t' };
        case 8: return { text: 'Déclarations', picture: 'undraw_terms_re_6ak4' };
        case 9: return { text: 'Confirmation', picture: 'undraw_confidential_letter_w6ux' };
        default: return { text: 'Succès', picture: 'undraw_well_done_i2wr' };
      }
    },

    countriesInEurope() {
      const allowedCountries = [
        'Allemagne', 'Belgique', 'Autriche', 'Bulgarie', 'Chypre', 'Croatie', 'Danemark',
        'Espagne', 'Estonie', 'Finlande', 'France', 'Grèce', 'Hongrie', 'Irlande', 'Italie',
        'Lettonie', 'Lituanie', 'Luxembourg', 'Malte', 'Pays-Bas', 'Pologne', 'Portugal',
        'République Tchèque', 'Roumanie', 'Slovaquie', 'Slovénie', 'Suède',
      ];
      return this.countries.filter((country) => allowedCountries.includes(country.country_name));
    },

    countryCodeList() {
      return this.countries.map((country) => country.code);
    },

    willGraduateThisYear() {
      return this.$store.state.student.student.willGraduateThisYear;
    },

    cursusList() {
      return [...new Set(this.cursus.map((cursus) => cursus.name))];
    },
  },

  methods: {
    async getCountries() {
      try {
        this.countriesAreLoading = true;
        const response = await this.$http.get('guest/getCountries.php');
        this.countries = response.data;
      } catch (error) {
        this.$store.commit('SET_NOTIFICATION', {
          isVisible: true,
          text: 'Impossible de récupérer la liste des pays, rechargez la page',
          color: 'error',
        });
      } finally {
        this.countriesAreLoading = false;
      }
    },

    async sendForm() {
      let notificationText;
      let notificationColor = 'success';
      try {
        this.isSendingForm = true;
        const data = this.$store.state.student.student;
        const response = await this.$http.post('guest/saveRegistrationPara.php', data);
        notificationText = response.data;
        this.step += 1;
        this.$store.commit('student/RESET_STUDENT');
      } catch (error) {
        if (error.response?.status === 403) this.submitIsForbidden = true;
        notificationText = error.response?.data || 'Aucune réponse du serveur';
        notificationColor = 'error';
      } finally {
        this.isSendingForm = false;
        this.$store.commit('SET_NOTIFICATION', {
          isVisible: true,
          text: notificationText,
          color: notificationColor,
        });
      }
    },

    goNext() {
      if (this.step === 9) this.sendForm();
      else if (this.step === 1 || this.$refs[`step${this.step}Form`].validate()) {
        if (this.step === 6 && this.willGraduateThisYear) this.step += 2;
        else this.step += 1;
      }
    },

    goBack() {
      if (this.step === 8 && this.willGraduateThisYear) this.step -= 2;
      else this.step -= 1;
    },

    jumpToStep(target, toggle) {
      if (target <= this.step) toggle();
      else {
        let i = this.step;
        while (i < target && (i === 1 || this.$refs[`step${i}Form`].validate())) {
          if (i === 6 && this.willGraduateThisYear) i += 2;
          else i += 1;
        }
        this.step = i;
      }
    },
  },
};
</script>

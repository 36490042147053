<template>
  <v-form ref="form">
    <v-checkbox
      :input-value="student.hasRegForOtherCursus"
      @change="updateStudent('hasRegForOtherCursus', $event)"
    >
      <template v-slot:label>
        J'ai sollicité pour cette année académique une inscription dans un ou plusieurs autre(s)
        cursus de la Haute Ecole de Hainaut Condorcet
      </template>
    </v-checkbox>

    <v-text-field
      v-if="student.hasRegForOtherCursus"
      label="Autre(s) cursus"
      :value="student.otherCursusStudRegistered"
      @input="updateStudent('otherCursusStudRegistered', $event)"
      required
      :rules="rules.otherCursusStudRegistered"
      validate-on-blur
      hint="Séparez-les par une virgule s'ils sont plusieurs"
      persistent-hint
      filled
    ></v-text-field>

    <v-checkbox
      :input-value="student.hasRegInOtherSchool"
      @change="updateStudent('hasRegInOtherSchool', $event)"
    >
      <template v-slot:label>
        J'ai sollicité pour cette année académique une inscription dans une ou plusieurs autre(s)
        Haute Ecole que la Haute Ecole de Hainaut Condorcet
      </template>
    </v-checkbox>

    <v-text-field
      v-if="student.hasRegInOtherSchool"
      label="Autre(s) Haute Ecole"
      :value="student.otherSchoolsStudRegistered"
      @input="updateStudent('otherSchoolsStudRegistered', $event)"
      required
      :rules="rules.otherSchoolsStudRegistered"
      validate-on-blur
      hint="Séparez-les par une virgule si elles sont plusieurs"
      persistent-hint
      filled
    ></v-text-field>

    <v-checkbox
      v-model="hasDeclaredOnHonor"
      :rules="[(v) => !!v || 'La déclaration sur l\'honneur est obligatoire']"
      validate-on-blur
    >
      <template v-slot:label>
        Je déclare sur l’honneur que les renseignements fournis sont complets et exacts.
      </template>
    </v-checkbox>

    <v-alert
      color="info"
      text
      class="text-justify mt-4"
      dense
    >
      <div class="text-decoration-underline font-weight-bold">Attention :</div>

      Tout étudiant usant de moyens frauduleux en matière administrative sera exclu
      définitivement et immédiatement de la Haute Ecole et ce, sans préjudice de poursuites
      judiciaires éventuelles (Article 47, Alinéa 4 du RGE).
    </v-alert>
  </v-form>
</template>

<script>
import { enfStudentFormRules, student } from '@/mixins/dataMixin';
import { validate, updateStudent } from '@/mixins/methodsMixin';

export default {
  name: 'FormStepOtherReg',

  mixins: [
    enfStudentFormRules,
    validate,
    updateStudent,
    student,
  ],

  data: () => ({
    hasDeclaredOnHonor: false,
  }),
};
</script>

<template>
  <v-form ref="form">
    <v-select
      label="Cursus"
      :items="cursus"
      item-text="name"
      item-value="id"
      @change="handleCursusNameChange($event)"
      :value="student.selectedCursusId"
      filled
      required
      :rules="[(v) => !!v || 'Ce champ est obligatoire']"
    ></v-select>

    <v-select
      v-for="(item, index) in availableLocations"
      :key="index"
      :label="`Implantation souhaitée ${index + 1}`"
      @change="handleLocationPrefChange(index, $event)"
      :value="student.selectedLocations[index]"
      :items="locationLists[index]"
      filled
      no-data-text="Choisissez par ordre de préférence"
      required
      :rules="[(v) => index !== 0 || !!v || 'Ce champ est obligatoire']"
      clearable
      :hint="getLocationHint(index)"
      persistent-hint
    ></v-select>
  </v-form>
</template>

<script>
import { student } from '@/mixins/dataMixin';
import { validate, updateStudent } from '@/mixins/methodsMixin';

export default {
  name: 'FormStepCursus',

  mixins: [
    validate,
    updateStudent,
    student,
  ],

  props: {
    cursus: Array,
  },

  data: () => ({
    locationLists: [],
  }),

  computed: {
    availableLocations() {
      const selectedCursus = this.cursus
        .find((cursus) => cursus.id === this.student.selectedCursusId);
      if (selectedCursus) return selectedCursus.est_name.split(', ');
      return [];
    },
  },

  created() {
    const locations = this.student.selectedLocations;
    if (locations.length) {
      for (let i = 0; i < locations.length; i += 1) {
        if (i === 0) {
          this.locationLists.splice(0, this.locationLists.length, [...this.availableLocations]);
        } else {
          this.locationLists
            .splice(i, this.locationLists.length, [...this.locationLists[i - 1]
              .filter((location) => location !== locations[i - 1])]);
        }
      }
    }
  },

  methods: {
    handleLocationPrefChange(index, value) {
      if (value === null) {
        this.$store.commit('student/DEL_LOCATIONS', index);

        this.locationLists.splice(index + 1);

        return;
      }
      // update selected location and delete following ones
      this.$store.commit('student/UPDATE_LOCATIONS', {
        start: index,
        end: this.student.selectedLocations.length,
        value,
      });

      if (index === this.availableLocations.length - 1) return;

      // delete following locations menus and update following one
      this.locationLists
        .splice(index + 1, this.locationLists.length, [...this.locationLists[index]
          .filter((location) => location !== value)]);
    },

    handleCursusNameChange(value) {
      this.updateStudent('selectedCursusId', value);
      this.locationLists
        .splice(0, this.locationLists.length, [...this.availableLocations]);
      this.$store.commit('student/RESET_LOCATIONS');
    },

    getLocationHint(index) {
      if (index === 0) return '';
      return 'Ce choix est optionnel';
    },
  },
};
</script>
